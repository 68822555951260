
import { ref } from 'vue'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { api } from '@/api/useAxios'
import { fromNow } from '@/utils/format'
import { Toast } from 'vant'
import { Review } from 'momai'
export default {

  setup: () => {
    const unreviewedComments = ref<Review[]>([])
    const totalNum = ref(-1)

    const fetch: FetchFunction = (pageData, loadEnd) => {
      api.post('/mmdailywork/unauditpage', pageData).then(({ total, records }) => {
        unreviewedComments.value.push(...records)
        totalNum.value = total
        loadEnd()
      })
    }

    const removeACommentByIdx = (idx: number) => unreviewedComments.value.splice(idx, 1)

    return {
      unreviewedComments,
      ...useLoadList({
        list: unreviewedComments,
        total: totalNum,
        fetch
      }),
      fromNow,
      passComment: ({ id }: Review, i: number) => {
        api.post('/mmdailywork/update', {
          id,
          dealTag: 1
        }).then(() => {
          removeACommentByIdx(i)
          Toast.success('已通过！')
        })
      },
      delComment: ({ id }: Review, i: number) => {
        api.post('/mmdailywork/update', {
          id,
          dealTag: 2
        }).then(() => {
          removeACommentByIdx(i)
          Toast.success('不通过！')
        })
      }
    }
  }
}
